<template>
	<div class="register">
		<div v-if="successRegister" class="register-container">
			<img src="@/assets/yrylogo.png" alt="" />

			<div class="register-text">注册信息</div>
			<el-form
				:model="ruleForm"
				status-icon
				:rules="rules"
				ref="ruleForm"
				class="demo-ruleForm"
				style="width: 500px"
				label-position="rigth"
				label-width="120px"
			>
				<el-form-item
					label="姓名:"
					:rules="[{ required: true, message: '姓名不能为空' }]"
					prop="alias"
				>
					<el-input
						placeholder="请输入姓名"
						v-model="ruleForm.alias"
					></el-input>
				</el-form-item>
				<el-form-item label="手机号:" prop="mobile">
					<el-input
						placeholder="请输入手机号"
						v-model="ruleForm.mobile"
					></el-input>
				</el-form-item>
				<el-form-item
					:rules="[
						{
							required: true,
							message: '请输入密码',
							trigger: 'blur',
						},
						{
							min: 5,
							max: 25,
							message: '长度在 5 到 25个字符',
						},
						{
							pattern:
								/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{4,20}$/,
							message: '密码必须是由4-20位字母+数字组合',
						},
					]"
					label="密码:"
					prop="password"
				>
					<el-input
						type="password"
						v-model="ruleForm.password"
						autocomplete="off"
						placeholder="请输入密码"
					></el-input>
				</el-form-item>
				<el-form-item label="确认密码:" prop="checkPass">
					<el-input
						type="password"
						v-model="ruleForm.checkPass"
						autocomplete="off"
						placeholder="请输入确认密码"
					></el-input>
				</el-form-item>
				<el-form-item
					:rules="[{ required: true, message: '职务不能为空' }]"
					label="职务:"
					prop="post"
				>
					<el-select
						style="width: 100%"
						v-model="ruleForm.post"
						placeholder="请选择职务"
					>
						<el-option
							v-for="item in options"
							:key="item.value"
							:label="item.label"
							:value="item.value"
						>
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item
					:rules="[{ required: true, message: '所属机构不能为空' }]"
					label="所属机构:"
					prop="registerOrganization"
				>
					<el-input
						placeholder="请输入所属机构"
						v-model="ruleForm.registerOrganization"
					></el-input>
				</el-form-item>
				<el-form-item
					:rules="[
						{ required: true, message: '所属网点/部门不能为空' },
					]"
					label="所属网点/部门:"
					prop="registerNetwork"
				>
					<el-input
						placeholder="请输入所属网点/部门"
						v-model="ruleForm.registerNetwork"
					></el-input>
				</el-form-item>
				<el-form-item
					:rules="[{ required: true, message: '个人简介不能为空' }]"
					label="个人简介:"
					prop="registerProfile"
				>
					<el-input
						type="textarea"
						placeholder="请输入个人简介"
						v-model="ruleForm.registerProfile"
						:autosize="{ minRows: 3, maxRows: 14 }"
					></el-input>
				</el-form-item>
				<el-form-item class="register-btn">
					<el-button
						style="width: 100%"
						type="primary"
						@click="submitForm('ruleForm')"
						:loading="loading"
						>注册</el-button
					>
					<el-button
						style="width: 100%"
						@click="$router.replace('/login')"
						>返回</el-button
					>
				</el-form-item>
			</el-form>
		</div>
		<div class="success-register" v-else>
			<el-result icon="success"> </el-result>
			<p>
				注册成功,{{ second }}秒后将自动跳转到登录页面,<span
					@click="$router.replace('/login')"
					>跳转到登录页面</span
				>
			</p>
		</div>
	</div>
</template>

<script>
import { itemByType, userRegister } from '@/api/index.js';
export default {
	name: 'Register',
	data() {
		const checkUserName = (rule, value, callback) => {
			let reg = /^1[345789]\d{9}$/;
			if (!reg.test(value)) {
				callback(new Error('请输入正确的手机号'));
			} else {
				callback();
			}
		};

		const validatePass2 = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请再次输入密码'));
			} else if (value !== this.ruleForm.password) {
				callback(new Error('两次输入密码不一致!'));
			} else {
				callback();
			}
		};
		return {
			ruleForm: {
				mobile: '',
				alias: '',
				password: '',
				checkPass: '',
				post: '',
				registerOrganization: '',
				registerNetwork: '',
				registerProfile: '',
			},
			rules: {
				checkPass: [
					{
						required: true,
						validator: validatePass2,
						trigger: 'blur',
					},
				],
				mobile: [
					{
						required: true,
						validator: checkUserName,
						trigger: 'blur',
					},
				],
			},
			options: [],
			loading: false,
			successRegister: true,
			second: 5,
		};
	},
	created() {
		this.itemByType();
	},
	methods: {
		submitForm(formName) {
			this.$refs[formName].validate((valid) => {
				if (valid) {
					this.userRegister();
				} else {
					console.log('error submit!!');
					return false;
				}
			});
		},
		resetForm(formName) {
			this.$refs[formName].resetFields();
		},
		async itemByType() {
			const res = await itemByType({
				dictionaryType: 'CooperationPresidentPost',
			});
			if (res.returncode === 0) {
				this.options = res.data.map((item) => ({
					value: item.itemValue,
					label: item.itemName,
				}));
			}
		},
		async userRegister() {
			this.loading = true;

			const res = await userRegister(this.ruleForm);
			this.loading = false;

			if (res.returncode === 0) {
				this.count();

				this.successRegister = false;
				this.$message({
					message: '注册成功，请登录！',
					type: 'success',
				});
			}
		},
		count(num = 5) {
			setTimeout(() => {
				this.second = num - 1;
				if (!(this.second < 1)) {
					this.count(this.second);
				} else {
					this.$router.replace('/index');
				}
			}, 1000);
		},
	},
};
</script>

<style lang="scss" scoped>
.register {
	overflow: auto;
	display: flex;
	justify-content: center;
	width: 100%;
	height: 100%;
	background: #f0f2f5 url(~@/assets/background.svg) no-repeat 50%;
	img {
		position: absolute;
		z-index: 1;
		left: 0;
		right: 0;
		margin: auto;
		top: 70px;
		height: 44px;
	}
	// > img {
	// 	position: absolute;
	// 	top: 100px;
	// }
}
/* reset element-ui css */
.register-container {
	display: flex;
	justify-content: center;
	border-radius: 10px;
	padding: 170px 20px 20px 0px;
	position: relative;
}
.register-text {
	position: absolute;
	z-index: 1;
	left: 60px;
	top: 140px;
	color: #000;
	font-weight: 800;
	cursor: pointer;
	font-size: 16px;
}
::v-deep .register-btn div {
	display: flex;
}
.success-register p {
	font-weight: 800;
	span {
		color: #5660d5;
		text-decoration: underline;
		cursor: pointer;
	}
}
</style>

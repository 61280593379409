var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"register"},[(_vm.successRegister)?_c('div',{staticClass:"register-container"},[_c('img',{attrs:{"src":require("@/assets/yrylogo.png"),"alt":""}}),_c('div',{staticClass:"register-text"},[_vm._v("注册信息")]),_c('el-form',{ref:"ruleForm",staticClass:"demo-ruleForm",staticStyle:{"width":"500px"},attrs:{"model":_vm.ruleForm,"status-icon":"","rules":_vm.rules,"label-position":"rigth","label-width":"120px"}},[_c('el-form-item',{attrs:{"label":"姓名:","rules":[{ required: true, message: '姓名不能为空' }],"prop":"alias"}},[_c('el-input',{attrs:{"placeholder":"请输入姓名"},model:{value:(_vm.ruleForm.alias),callback:function ($$v) {_vm.$set(_vm.ruleForm, "alias", $$v)},expression:"ruleForm.alias"}})],1),_c('el-form-item',{attrs:{"label":"手机号:","prop":"mobile"}},[_c('el-input',{attrs:{"placeholder":"请输入手机号"},model:{value:(_vm.ruleForm.mobile),callback:function ($$v) {_vm.$set(_vm.ruleForm, "mobile", $$v)},expression:"ruleForm.mobile"}})],1),_c('el-form-item',{attrs:{"rules":[
					{
						required: true,
						message: '请输入密码',
						trigger: 'blur',
					},
					{
						min: 5,
						max: 25,
						message: '长度在 5 到 25个字符',
					},
					{
						pattern:
							/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{4,20}$/,
						message: '密码必须是由4-20位字母+数字组合',
					} ],"label":"密码:","prop":"password"}},[_c('el-input',{attrs:{"type":"password","autocomplete":"off","placeholder":"请输入密码"},model:{value:(_vm.ruleForm.password),callback:function ($$v) {_vm.$set(_vm.ruleForm, "password", $$v)},expression:"ruleForm.password"}})],1),_c('el-form-item',{attrs:{"label":"确认密码:","prop":"checkPass"}},[_c('el-input',{attrs:{"type":"password","autocomplete":"off","placeholder":"请输入确认密码"},model:{value:(_vm.ruleForm.checkPass),callback:function ($$v) {_vm.$set(_vm.ruleForm, "checkPass", $$v)},expression:"ruleForm.checkPass"}})],1),_c('el-form-item',{attrs:{"rules":[{ required: true, message: '职务不能为空' }],"label":"职务:","prop":"post"}},[_c('el-select',{staticStyle:{"width":"100%"},attrs:{"placeholder":"请选择职务"},model:{value:(_vm.ruleForm.post),callback:function ($$v) {_vm.$set(_vm.ruleForm, "post", $$v)},expression:"ruleForm.post"}},_vm._l((_vm.options),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1),_c('el-form-item',{attrs:{"rules":[{ required: true, message: '所属机构不能为空' }],"label":"所属机构:","prop":"registerOrganization"}},[_c('el-input',{attrs:{"placeholder":"请输入所属机构"},model:{value:(_vm.ruleForm.registerOrganization),callback:function ($$v) {_vm.$set(_vm.ruleForm, "registerOrganization", $$v)},expression:"ruleForm.registerOrganization"}})],1),_c('el-form-item',{attrs:{"rules":[
					{ required: true, message: '所属网点/部门不能为空' } ],"label":"所属网点/部门:","prop":"registerNetwork"}},[_c('el-input',{attrs:{"placeholder":"请输入所属网点/部门"},model:{value:(_vm.ruleForm.registerNetwork),callback:function ($$v) {_vm.$set(_vm.ruleForm, "registerNetwork", $$v)},expression:"ruleForm.registerNetwork"}})],1),_c('el-form-item',{attrs:{"rules":[{ required: true, message: '个人简介不能为空' }],"label":"个人简介:","prop":"registerProfile"}},[_c('el-input',{attrs:{"type":"textarea","placeholder":"请输入个人简介","autosize":{ minRows: 3, maxRows: 14 }},model:{value:(_vm.ruleForm.registerProfile),callback:function ($$v) {_vm.$set(_vm.ruleForm, "registerProfile", $$v)},expression:"ruleForm.registerProfile"}})],1),_c('el-form-item',{staticClass:"register-btn"},[_c('el-button',{staticStyle:{"width":"100%"},attrs:{"type":"primary","loading":_vm.loading},on:{"click":function($event){return _vm.submitForm('ruleForm')}}},[_vm._v("注册")]),_c('el-button',{staticStyle:{"width":"100%"},on:{"click":function($event){return _vm.$router.replace('/login')}}},[_vm._v("返回")])],1)],1)],1):_c('div',{staticClass:"success-register"},[_c('el-result',{attrs:{"icon":"success"}}),_c('p',[_vm._v(" 注册成功,"+_vm._s(_vm.second)+"秒后将自动跳转到登录页面,"),_c('span',{on:{"click":function($event){return _vm.$router.replace('/login')}}},[_vm._v("跳转到登录页面")])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }